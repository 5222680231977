/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}
.btn-default.disabled,
.btn-primary.disabled,
.btn-success.disabled,
.btn-info.disabled,
.btn-warning.disabled,
.btn-danger.disabled,
.btn-default[disabled],
.btn-primary[disabled],
.btn-success[disabled],
.btn-info[disabled],
.btn-warning[disabled],
.btn-danger[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-danger {
  box-shadow: none;
}
.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
  text-shadow: none;
}
.btn:active,
.btn.active {
  background-image: none;
}
.btn-default {
  text-shadow: 0 1px 0 #fff;
  background-image:         linear-gradient(to bottom, #fff 0%, #e0e0e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}
.btn-default:hover,
.btn-default:focus {
  background-color: #e0e0e0;
  background-position: 0 -15px;
}
.btn-default:active,
.btn-default.active {
  background-color: #e0e0e0;
  border-color: #dbdbdb;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #e0e0e0;
  background-image: none;
}
.btn-primary {
  background-image:         linear-gradient(to bottom, #337ab7 0%, #265a88 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff265a88', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #245580;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #265a88;
  background-position: 0 -15px;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #265a88;
  border-color: #245580;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #265a88;
  background-image: none;
}
.btn-success {
  background-image:         linear-gradient(to bottom, #5cb85c 0%, #419641 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #3e8f3e;
}
.btn-success:hover,
.btn-success:focus {
  background-color: #419641;
  background-position: 0 -15px;
}
.btn-success:active,
.btn-success.active {
  background-color: #419641;
  border-color: #3e8f3e;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #419641;
  background-image: none;
}
.btn-info {
  background-image:         linear-gradient(to bottom, #5bc0de 0%, #2aabd2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2aabd2', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #28a4c9;
}
.btn-info:hover,
.btn-info:focus {
  background-color: #2aabd2;
  background-position: 0 -15px;
}
.btn-info:active,
.btn-info.active {
  background-color: #2aabd2;
  border-color: #28a4c9;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #2aabd2;
  background-image: none;
}
.btn-warning {
  background-image:         linear-gradient(to bottom, #f0ad4e 0%, #eb9316 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffeb9316', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #e38d13;
}
.btn-warning:hover,
.btn-warning:focus {
  background-color: #eb9316;
  background-position: 0 -15px;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #eb9316;
  border-color: #e38d13;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #eb9316;
  background-image: none;
}
.btn-danger {
  background-image:         linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc12e2a', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #b92c28;
}
.btn-danger:hover,
.btn-danger:focus {
  background-color: #c12e2a;
  background-position: 0 -15px;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #c12e2a;
  border-color: #b92c28;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #c12e2a;
  background-image: none;
}
.thumbnail,
.img-thumbnail {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #e8e8e8;
  background-image:         linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #2e6da4;
  background-image:         linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  background-repeat: repeat-x;
}
.navbar-default {
  background-image:         linear-gradient(to bottom, #fff 0%, #f8f8f8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .active > a {
  background-image:         linear-gradient(to bottom, #dbdbdb 0%, #e2e2e2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdbdbdb', endColorstr='#ffe2e2e2', GradientType=0);
  background-repeat: repeat-x;
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
}
.navbar-inverse {
  background-image:         linear-gradient(to bottom, #3c3c3c 0%, #222 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-radius: 4px;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
  background-image:         linear-gradient(to bottom, #080808 0%, #0f0f0f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff080808', endColorstr='#ff0f0f0f', GradientType=0);
  background-repeat: repeat-x;
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, .25);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
}
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-image:         linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
    background-repeat: repeat-x;
  }
}
.alert {
  text-shadow: 0 1px 0 rgba(255, 255, 255, .2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05);
}
.alert-success {
  background-image:         linear-gradient(to bottom, #dff0d8 0%, #c8e5bc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffc8e5bc', GradientType=0);
  background-repeat: repeat-x;
  border-color: #b2dba1;
}
.alert-info {
  background-image:         linear-gradient(to bottom, #d9edf7 0%, #b9def0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
  background-repeat: repeat-x;
  border-color: #9acfea;
}
.alert-warning {
  background-image:         linear-gradient(to bottom, #fcf8e3 0%, #f8efc0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fff8efc0', GradientType=0);
  background-repeat: repeat-x;
  border-color: #f5e79e;
}
.alert-danger {
  background-image:         linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
  background-repeat: repeat-x;
  border-color: #dca7a7;
}
.progress {
  background-image:         linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar {
  background-image:         linear-gradient(to bottom, #337ab7 0%, #286090 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff286090', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-success {
  background-image:         linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-info {
  background-image:         linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-warning {
  background-image:         linear-gradient(to bottom, #f0ad4e 0%, #ec971f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffec971f', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-danger {
  background-image:         linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-striped {
  background-image:         linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}
.list-group {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 #286090;
  background-image:         linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2b669a', GradientType=0);
  background-repeat: repeat-x;
  border-color: #2b669a;
}
.list-group-item.active .badge,
.list-group-item.active:hover .badge,
.list-group-item.active:focus .badge {
  text-shadow: none;
}
.panel {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}
.panel-default > .panel-heading {
  background-image:         linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
}
.panel-primary > .panel-heading {
  background-image:         linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  background-repeat: repeat-x;
}
.panel-success > .panel-heading {
  background-image:         linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
  background-repeat: repeat-x;
}
.panel-info > .panel-heading {
  background-image:         linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
  background-repeat: repeat-x;
}
.panel-warning > .panel-heading {
  background-image:         linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
  background-repeat: repeat-x;
}
.panel-danger > .panel-heading {
  background-image:         linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
  background-repeat: repeat-x;
}
.well {
  background-image:         linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
  border-color: #dcdcdc;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05), 0 1px 0 rgba(255, 255, 255, .1);
}
/*# sourceMappingURL=bootstrap-theme.css.map */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
      padding-top: 50px;
  }    
}

/* to correct background of modal popup */
.modal-backdrop {
  background-color: transparent !important;
}

/* to correct calendar position - below the label */
.react-date-picker__calendar {
  top: 100% !important;
}

.adduser-width{
  width: calc(80% - 20px);
}

.m-top-50{
  margin-top: 50px !important;
}

.AccessDenied {
  background-image: url(/BillPay/static/media/access-denied.32b98ce2.jpg);
  max-width: 300px;
  min-height: 200px;
}

.welcome {
  font-weight: bold;
  color: white !important;
  padding: 10px 16px;
  border-radius: 4px;
}

.acecsi-logo {
  font-weight: bold;
  color: white !important;
}

.highlighted-text {
  background-color: yellow;
}

th, td {
  padding: 10px;
  text-align: left;
}

.hideElement {
  display: none;
}

.center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.error {
  color: red;
}
.navbar li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.navbar-dark .navbar-nav .active a,
.navbar-dark .navbar-nav .active a:hover,
.navbar-dark .navbar-nav .active a:focus {
    background-image: none;
    background-color: #4189C7;
    color: white;
    margin-right: auto !important;
}

.navbar-dark li a {
    padding: 10px 16px;
    border-radius: 4px;
    border-bottom-color: black;
    background-color: #FFF8DC;
    color: #343A40;    
}

.right-menu {
    padding: 10px 16px;
}

.left-menu {
    float: left;
    padding-left: 10px;   
    margin-bottom: 1px; 
}
.left-menu a {
    color: black !important;
}
table a,table a:visited {
    color: #669966 !important;
}
td {
    padding: 0 0 0 5px !important;
}
.dropdown-toggle {
    background-color: #669966 !important;
    background-image: none;
}
.green {
    background-color: #669966;
}
.col.green span {
    color: white;
}
.left {
    float:left;
}
.lowest {
    z-index: 1;
}
.bkgimg {
    height: 108px;
    width: 100%;
    position: absolute;
    z-index: 0;
}
.mnlogo {
    position: relative;
    z-index: 3;
}
.clear {
    clear: both;
}
.bolder {
    font-weight: bolder;
}


.link span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .7em;
}
.buttonBg {
    background-color: #669966;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}
.padBackBtn {
    margin-bottom: 20px;
}
.pgtitle {
    color: blue;
    font-size: 1.2em;
    font-weight: bolder;
    margin-left: 30px;
}

button {
    overflow: visible;
    width: auto;
}

button.link {
    font-family: "Verdana" sans-serif;
    font-size: 1em;
    text-align: left;
    color: #669966;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
}

button.link span {
    text-decoration: none;
}
button.link:hover span,
button.link:focus span {
    color: black;
}
label.form-control {
    border: none !important;
}
div.container.marginme {
    margin: 30px;
}

.Table-header {
    background-color: #ccc;
    color: blue;
    padding: 10px;
    text-align: left;
}
.table-borderless td,
.table-borderless th {
    border: 0;
}
.tablec th, .tablec td {
    padding: 0;
}
.tablec a {
    color: #007bff !important;
}
.react-bs-table-container {
    margin: 0 !important;
}

.nav-item {
    margin-right: 10px;
}
/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
            display: block;
        }

            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

        tr {
            margin: 0 0 1rem 0;
        }

            tr:nth-child(odd) {
                background: #ccc;
            }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50% !important;
        }

            td:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 0;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
            }

    }

    .react-bs-table-container {
    margin: 5px 10px;
}

.tr-string-example, .th-string-example{
    font-style: oblique;
    color: red;
    background-color: aquamarine;
}

.tr-function-example{
    font-weight: bold;
    background-color: aquamarine;
}

.td-header-string-example{
    font-style: oblique;
    color: red;
    background-color: aquamarine;
}

.td-column-string-example{
    color: blue;
    background-color: antiquewhite;
}

.td-column-function-odd-example{
    color: blue;
    background-color: antiquewhite;
}

.td-column-function-even-example{
    color: red;
    background-color: yellowgreen;
}

.my-header-class {
    background-color: #0066FF;
}

.my-body-class {
    background-color: #CC00FF;
}

.my-container-class {
    border: #0000FF 2.5px solid;
}

.my-table-container-class {
    border: #FFBB73 5px solid;
}

.class-for-editing-cell {
    background-color: #FFBB73;
}
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .ptoday td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 0.75rem !important;
    }
    
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .todaysact td:nth-of-type(1):before {content: "Date ";}
    .todaysact td:nth-of-type(2):before {content: "Center ";}
    .todaysact td:nth-of-type(3):before {content: "Person ";}
    .todaysact td:nth-of-type(4):before {content: "Account ";}
    .todaysact td:nth-of-type(5):before {content: "Amount ";}
    .todaysact td:nth-of-type(6):before {content: "Fee ";}
    .todaysact td:nth-of-type(7):before {content: "Phone ";}
    .todaysact td:nth-of-type(8):before {content: "Paid by ";}
    .todaysact td:nth-of-type(9):before {content: "Code ";}
    .todaysact td:nth-of-type(10):before {content: "Trans Num ";}
    .todaysact td:nth-of-type(11):before {content: "Auth Num ";}

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .date td:nth-of-type(1):before {content: "Date ";}
    .date td:nth-of-type(2):before {content: "Center ";}
    .date td:nth-of-type(3):before {content: "Person ";}
    .date td:nth-of-type(4):before {content: "Account ";}
    .date td:nth-of-type(5):before {content: "Amount ";}
    .date td:nth-of-type(6):before {content: "Fee ";}
    .date td:nth-of-type(7):before {content: "Phone ";}
    .date td:nth-of-type(8):before {content: "Paid By ";}
    .date td:nth-of-type(9):before {content: "Code ";}
    .date td:nth-of-type(10):before {content: "Trans Num ";}
    .date td:nth-of-type(11):before {content: "Auth # ";}

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .centertbl td:nth-of-type(1):before {content: "Center ";}
    .centertbl td:nth-of-type(2):before {content: "Address ";}
    .centertbl td:nth-of-type(3):before {content: "City ";}
    .centertbl td:nth-of-type(4):before {content: "State ";}
    .centertbl td:nth-of-type(5):before {content: "Zip ";}
    .centertbl td:nth-of-type(6):before {content: "Phone ";}
    .centertbl td:nth-of-type(7):before {content: "Fax ";}
    .centertbl td:nth-of-type(8):before {content: "Date Sent ";}

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .account td:nth-of-type(1):before {
        content: "Account # ";
    }

    .account td:nth-of-type(2):before {
        content: "Code ";
    }

    .account td:nth-of-type(3):before {
        content: "Amount ";
    }

    .account td:nth-of-type(4):before {
        content: "Center ";
    }

    .account td:nth-of-type(5):before {
        content: "Trans Date ";
    }

    .account td:nth-of-type(6):before {
        content: "Time CST ";
    }

    .account td:nth-of-type(7):before {
        content: "Date Sent ";
    }

    .account td:nth-of-type(8):before {
        content: "Batch # ";
    }

    .account td:nth-of-type(9):before {
        content: "Auth # ";
    }

    .account td:nth-of-type(10):before {
        content: "Name ";
    }

    .account td:nth-of-type(11):before {
        content: "Phone ";
    }
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .acctcenteradj td:nth-of-type(1):before {content: "Auth ";}
    .acctcenteradj td:nth-of-type(2):before {content: "Code ";}
    .acctcenteradj td:nth-of-type(3):before {content: "Orig ";}
    .acctcenteradj td:nth-of-type(4):before {content: "Center ";}
    .acctcenteradj td:nth-of-type(5):before {content: "Orig Date ";}
    .acctcenteradj td:nth-of-type(6):before {content: "Adjust Date ";}
    .acctcenteradj td:nth-of-type(7):before {content: "Adjust Time ";}
    .acctcenteradj td:nth-of-type(8):before {content: "New Amt ";}
    .acctcenteradj td:nth-of-type(9):before {content: "Delta ";}
    .acctcenteradj td:nth-of-type(10):before {content: "Reason ";}

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .acctcorpadj td:nth-of-type(1):before {content: "Account ";}
    .acctcorpadj td:nth-of-type(2):before {content: "Code ";}
    .acctcorpadj td:nth-of-type(3):before {content: "Amount ";}
    .acctcorpadj td:nth-of-type(4):before {content: "Center ";}
    .acctcorpadj td:nth-of-type(5):before {content: "Orig Date ";}
    .acctcorpadj td:nth-of-type(6):before {content: "Date Sent ";}
    .acctcorpadj td:nth-of-type(7):before {content: "Batch # ";}
    .acctcorpadj td:nth-of-type(8):before {content: "Auth # ";}
    .acctcorpadj td:nth-of-type(9):before {content: "Name ";}
    .acctcorpadj td:nth-of-type(10):before {content: "Phone ";}

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .authnum td:nth-of-type(1):before {
        content: "Account # ";
    }

    .authnum td:nth-of-type(2):before {
        content: "Code ";
    }

    .authnum td:nth-of-type(3):before {
        content: "Amount ";
    }

    .authnum td:nth-of-type(4):before {
        content: "Center ";
    }

    .authnum td:nth-of-type(5):before {
        content: "Trans Date ";
    }

    .authnum td:nth-of-type(6):before {
        content: "Time CST ";
    }

    .authnum td:nth-of-type(7):before {
        content: "Date Sent ";
    }

    .authnum td:nth-of-type(8):before {
        content: "Batch # ";
    }

    .authnum td:nth-of-type(9):before {
        content: "Auth # ";
    }

    .authnum td:nth-of-type(10):before {
        content: "Name ";
    }

    .authnum td:nth-of-type(11):before {
        content: "Phone ";
    }
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .authcenteradj td:nth-of-type(1):before {content: "Auth ";}
    .authcenteradj td:nth-of-type(2):before {content: "Code ";}
    .authcenteradj td:nth-of-type(3):before {content: "Orig ";}
    .authcenteradj td:nth-of-type(4):before {content: "Center ";}
    .authcenteradj td:nth-of-type(5):before {content: "Orig Date ";}
    .authcenteradj td:nth-of-type(6):before {content: "Adjust Date ";}
    .authcenteradj td:nth-of-type(7):before {content: "Adjust Time ";}
    .authcenteradj td:nth-of-type(8):before {content: "New Amt ";}
    .authcenteradj td:nth-of-type(9):before {content: "Delta ";}
    .authcenteradj td:nth-of-type(10):before {content: "Reason ";}

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .authcorpadj td:nth-of-type(1):before {content: "Account ";}
    .authcorpadj td:nth-of-type(2):before {content: "Code ";}
    .authcorpadj td:nth-of-type(3):before {content: "Amount ";}
    .authcorpadj td:nth-of-type(4):before {content: "Center ";}
    .authcorpadj td:nth-of-type(5):before {content: "Orig Date ";}
    .authcorpadj td:nth-of-type(6):before {content: "Date Sent ";}
    .authcorpadj td:nth-of-type(7):before {content: "Batch # ";}
    .authcorpadj td:nth-of-type(8):before {content: "Auth # ";}
    .authcorpadj td:nth-of-type(9):before {content: "Name ";}
    .authcorpadj td:nth-of-type(10):before {content: "Phone ";}

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .lastname td:nth-of-type(1):before {content: "Account ";}
    .lastname td:nth-of-type(2):before {content: "First Name ";}
    .lastname td:nth-of-type(3):before {content: "Last Name ";}
    .lastname td:nth-of-type(4):before {content: "Address 1 ";}
    .lastname td:nth-of-type(5):before {content: "Address 2 ";}
    .lastname td:nth-of-type(6):before {content: "City ";}
    .lastname td:nth-of-type(7):before {content: "State ";}
    .lastname td:nth-of-type(8):before {content: "Zip ";}

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .misacct td:nth-of-type(1):before {content: "Account ";}
    .misacct td:nth-of-type(2):before {content: "Code ";}
    .misacct td:nth-of-type(3):before {content: "Amount ";}
    .misacct td:nth-of-type(4):before {content: "Center ";}
    .misacct td:nth-of-type(5):before {content: "Trans Date ";}
    .misacct td:nth-of-type(6):before {content: "Time CST ";}

}

