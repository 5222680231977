.navbar li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.navbar-dark .navbar-nav .active a,
.navbar-dark .navbar-nav .active a:hover,
.navbar-dark .navbar-nav .active a:focus {
    background-image: none;
    background-color: #4189C7;
    color: white;
    margin-right: auto !important;
}

.navbar-dark li a {
    padding: 10px 16px;
    border-radius: 4px;
    border-bottom-color: black;
    background-color: #FFF8DC;
    color: #343A40;    
}

.right-menu {
    padding: 10px 16px;
}

.left-menu {
    float: left;
    padding-left: 10px;   
    margin-bottom: 1px; 
}
.left-menu a {
    color: black !important;
}