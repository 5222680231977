@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .todaysact td:nth-of-type(1):before {content: "Date ";}
    .todaysact td:nth-of-type(2):before {content: "Center ";}
    .todaysact td:nth-of-type(3):before {content: "Person ";}
    .todaysact td:nth-of-type(4):before {content: "Account ";}
    .todaysact td:nth-of-type(5):before {content: "Amount ";}
    .todaysact td:nth-of-type(6):before {content: "Fee ";}
    .todaysact td:nth-of-type(7):before {content: "Phone ";}
    .todaysact td:nth-of-type(8):before {content: "Paid by ";}
    .todaysact td:nth-of-type(9):before {content: "Code ";}
    .todaysact td:nth-of-type(10):before {content: "Trans Num ";}
    .todaysact td:nth-of-type(11):before {content: "Auth Num ";}

}
