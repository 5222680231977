@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .authnum td:nth-of-type(1):before {
        content: "Account # ";
    }

    .authnum td:nth-of-type(2):before {
        content: "Code ";
    }

    .authnum td:nth-of-type(3):before {
        content: "Amount ";
    }

    .authnum td:nth-of-type(4):before {
        content: "Center ";
    }

    .authnum td:nth-of-type(5):before {
        content: "Trans Date ";
    }

    .authnum td:nth-of-type(6):before {
        content: "Time CST ";
    }

    .authnum td:nth-of-type(7):before {
        content: "Date Sent ";
    }

    .authnum td:nth-of-type(8):before {
        content: "Batch # ";
    }

    .authnum td:nth-of-type(9):before {
        content: "Auth # ";
    }

    .authnum td:nth-of-type(10):before {
        content: "Name ";
    }

    .authnum td:nth-of-type(11):before {
        content: "Phone ";
    }
}
