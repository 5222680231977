table a,table a:visited {
    color: #669966 !important;
}
td {
    padding: 0 0 0 5px !important;
}
.dropdown-toggle {
    background-color: #669966 !important;
    background-image: none;
}
.green {
    background-color: #669966;
}
.col.green span {
    color: white;
}
.left {
    float:left;
}
.lowest {
    z-index: 1;
}
.bkgimg {
    height: 108px;
    width: 100%;
    position: absolute;
    z-index: 0;
}
.mnlogo {
    position: relative;
    z-index: 3;
}
.clear {
    clear: both;
}
.bolder {
    font-weight: bolder;
}


.link span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .7em;
}
.buttonBg {
    background-color: #669966;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}
.padBackBtn {
    margin-bottom: 20px;
}
.pgtitle {
    color: blue;
    font-size: 1.2em;
    font-weight: bolder;
    margin-left: 30px;
}

button {
    overflow: visible;
    width: auto;
}

button.link {
    font-family: "Verdana" sans-serif;
    font-size: 1em;
    text-align: left;
    color: #669966;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
}

button.link span {
    text-decoration: none;
}
button.link:hover span,
button.link:focus span {
    color: black;
}
label.form-control {
    border: none !important;
}
div.container.marginme {
    margin: 30px;
}

.Table-header {
    background-color: #ccc;
    color: blue;
    padding: 10px;
    text-align: left;
}
.table-borderless td,
.table-borderless th {
    border: 0;
}
.tablec th, .tablec td {
    padding: 0;
}
.tablec a {
    color: #007bff !important;
}
.react-bs-table-container {
    margin: 0 !important;
}

.nav-item {
    margin-right: 10px;
}
/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
            display: block;
        }

            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

        tr {
            margin: 0 0 1rem 0;
        }

            tr:nth-child(odd) {
                background: #ccc;
            }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50% !important;
        }

            td:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 0;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
            }

    }

    .react-bs-table-container {
    margin: 5px 10px;
}

.tr-string-example, .th-string-example{
    font-style: oblique;
    color: red;
    background-color: aquamarine;
}

.tr-function-example{
    font-weight: bold;
    background-color: aquamarine;
}

.td-header-string-example{
    font-style: oblique;
    color: red;
    background-color: aquamarine;
}

.td-column-string-example{
    color: blue;
    background-color: antiquewhite;
}

.td-column-function-odd-example{
    color: blue;
    background-color: antiquewhite;
}

.td-column-function-even-example{
    color: red;
    background-color: yellowgreen;
}

.my-header-class {
    background-color: #0066FF;
}

.my-body-class {
    background-color: #CC00FF;
}

.my-container-class {
    border: #0000FF 2.5px solid;
}

.my-table-container-class {
    border: #FFBB73 5px solid;
}

.class-for-editing-cell {
    background-color: #FFBB73;
}