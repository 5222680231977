@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .centertbl td:nth-of-type(1):before {content: "Center ";}
    .centertbl td:nth-of-type(2):before {content: "Address ";}
    .centertbl td:nth-of-type(3):before {content: "City ";}
    .centertbl td:nth-of-type(4):before {content: "State ";}
    .centertbl td:nth-of-type(5):before {content: "Zip ";}
    .centertbl td:nth-of-type(6):before {content: "Phone ";}
    .centertbl td:nth-of-type(7):before {content: "Fax ";}
    .centertbl td:nth-of-type(8):before {content: "Date Sent ";}

}
