@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .acctcenteradj td:nth-of-type(1):before {content: "Auth ";}
    .acctcenteradj td:nth-of-type(2):before {content: "Code ";}
    .acctcenteradj td:nth-of-type(3):before {content: "Orig ";}
    .acctcenteradj td:nth-of-type(4):before {content: "Center ";}
    .acctcenteradj td:nth-of-type(5):before {content: "Orig Date ";}
    .acctcenteradj td:nth-of-type(6):before {content: "Adjust Date ";}
    .acctcenteradj td:nth-of-type(7):before {content: "Adjust Time ";}
    .acctcenteradj td:nth-of-type(8):before {content: "New Amt ";}
    .acctcenteradj td:nth-of-type(9):before {content: "Delta ";}
    .acctcenteradj td:nth-of-type(10):before {content: "Reason ";}

}
