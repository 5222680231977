@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .acctcorpadj td:nth-of-type(1):before {content: "Account ";}
    .acctcorpadj td:nth-of-type(2):before {content: "Code ";}
    .acctcorpadj td:nth-of-type(3):before {content: "Amount ";}
    .acctcorpadj td:nth-of-type(4):before {content: "Center ";}
    .acctcorpadj td:nth-of-type(5):before {content: "Orig Date ";}
    .acctcorpadj td:nth-of-type(6):before {content: "Date Sent ";}
    .acctcorpadj td:nth-of-type(7):before {content: "Batch # ";}
    .acctcorpadj td:nth-of-type(8):before {content: "Auth # ";}
    .acctcorpadj td:nth-of-type(9):before {content: "Name ";}
    .acctcorpadj td:nth-of-type(10):before {content: "Phone ";}

}
