body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
      padding-top: 50px;
  }    
}

/* to correct background of modal popup */
.modal-backdrop {
  background-color: transparent !important;
}

/* to correct calendar position - below the label */
.react-date-picker__calendar {
  top: 100% !important;
}

.adduser-width{
  width: calc(80% - 20px);
}

.m-top-50{
  margin-top: 50px !important;
}

.AccessDenied {
  background-image: url(./access-denied.jpg);
  max-width: 300px;
  min-height: 200px;
}

.welcome {
  font-weight: bold;
  color: white !important;
  padding: 10px 16px;
  border-radius: 4px;
}

.acecsi-logo {
  font-weight: bold;
  color: white !important;
}

.highlighted-text {
  background-color: yellow;
}

th, td {
  padding: 10px;
  text-align: left;
}

.hideElement {
  display: none;
}

.center {
    display: flex;
    justify-content: center;
    width: 100%;
}

.error {
  color: red;
}