@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .lastname td:nth-of-type(1):before {content: "Account ";}
    .lastname td:nth-of-type(2):before {content: "First Name ";}
    .lastname td:nth-of-type(3):before {content: "Last Name ";}
    .lastname td:nth-of-type(4):before {content: "Address 1 ";}
    .lastname td:nth-of-type(5):before {content: "Address 2 ";}
    .lastname td:nth-of-type(6):before {content: "City ";}
    .lastname td:nth-of-type(7):before {content: "State ";}
    .lastname td:nth-of-type(8):before {content: "Zip ";}

}
